<template>
  <div class="earning-cnt">
    <header class="inner-head">
      <div class="left-col">
        <span class="icon-btn" @click="$router.go(-1)">
          <BackIcon />
        </span>
        <h1>Earning</h1>
      </div>
    </header>

    <section class="mid-cnt">
      <div class="pay-veiwbox">
        <div class="commission-card">
          <div class="widraw-blk">
            <div class="col-item">
              <span class="txt-value">₹ 2549</span>
            </div>
            <div class="col-item">
              <span class="widraw-btn"> Withdraw Now </span>
            </div>
          </div>

          <p>अपने इस महीने कुल कमीशन बनाया है |</p>
        </div>
      </div>

      <div class="transaction-panel">
        <h2>Transaction History</h2>
        <div class="history-filter">
          <div class="col-filter">
            <div class="rel-box">
              <select class="select-opt">
                <option>March 2021</option>
                <option>February 2021</option>
                <option>January 2021</option>
                <option>December 2020</option>
                <option>November 2020</option>
                <option>October 2020</option>
                <option>September 2020</option>
                <option>August 2020</option>
                <option>July 2020</option>
                <option>June 2020</option>
                <option>May 2020</option>
                <option>April 2020</option>
                <option>March 2020</option>
                <option>February 2020</option>
                <option>January 2020</option>
                <option>December 2019</option>
                <option>November 2019</option>
                <option>October 2019</option>
                <option>September 2019</option>
                <option>August 2019</option>
              </select>
              <span class="drop-icon">
                <drop-carrot-icon />
              </span>
            </div>
          </div>
          <div class="col-filter">
            <div class="rel-box">
              <select class="select-opt">
                <option>All Task</option>
              </select>
              <span class="drop-icon">
                <drop-carrot-icon />
              </span>
            </div>
          </div>
        </div>

        <ul>
          <li>
            <div class="month-title">
              <span>Fri Nov 27 2020 </span>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Recharge of ₹49 in Airtel mobile +91 9876543210</p>
                <span class="time-staus">15:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt cr-amt">₹10</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt dr-amt">₹50</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt dr-amt">₹50</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt cr-amt">₹50</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt dr-amt">₹50</span>
              </div>
            </div>
          </li>

          <li>
            <div class="month-title">
              <span>Fri Nov 27 2020 </span>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Recharge of ₹49 in Airtel mobile +91 9876543210</p>
                <span class="time-staus">15:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt cr-amt">₹10</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt dr-amt">₹50</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt dr-amt">₹50</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt cr-amt">₹50</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt dr-amt">₹50</span>
              </div>
            </div>
          </li>

          <li>
            <div class="month-title">
              <span>Fri Nov 27 2020 </span>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Recharge of ₹49 in Airtel mobile +91 9876543210</p>
                <span class="time-staus">15:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt cr-amt">₹10</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt dr-amt">₹50</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt dr-amt">₹50</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt cr-amt">₹50</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt dr-amt">₹50</span>
              </div>
            </div>
          </li>

          <li>
            <div class="month-title">
              <span>Fri Nov 27 2020 </span>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Recharge of ₹49 in Airtel mobile +91 9876543210</p>
                <span class="time-staus">15:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt cr-amt">₹10</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt dr-amt">₹50</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt dr-amt">₹50</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt cr-amt">₹50</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt dr-amt">₹50</span>
              </div>
            </div>
          </li>

          <li>
            <div class="month-title">
              <span>Fri Nov 27 2020 </span>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Recharge of ₹49 in Airtel mobile +91 9876543210</p>
                <span class="time-staus">15:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt cr-amt">₹10</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt dr-amt">₹50</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt dr-amt">₹50</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt cr-amt">₹50</span>
              </div>
            </div>

            <div class="list-item">
              <div class="info-item">
                <p>Lead refer to Akshay for News91</p>
                <span class="time-staus">14:32 PM</span>
              </div>
              <div class="amt-item">
                <span class="txt-amt dr-amt">₹50</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import BackIcon from '@/components/svgIcons/BackIcon';
import DropCarrotIcon from '@/components/svgIcons/DropCarrotIcon';
export default {
  name: 'EarningHistory',
  components: {
    BackIcon,
    DropCarrotIcon,
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/earning-history.scss';
</style>
